import 'react-day-picker/dist/style.css'; // Fix issue: Global CSS cannot be imported from files other than your Custom

import { ChakraProvider } from '@chakra-ui/react';
import { theme } from '@diamond/shared/theme';
import * as Sentry from '@sentry/react';
import { StrictMode } from 'react';
import React from 'react';
import * as ReactDOM from 'react-dom/client';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

import App from './app';

Sentry.init({
  dsn: process.env['NX_SENTRY_DSN_ADMIN'],
  environment: process.env['NX_ENV'],
  attachStacktrace: true,
  autoSessionTracking: true,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100% dof transactions for performance monitoring.
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    /^https:\/\/api-v2\.sukandaonelink\.com\/api/,
    /^https:\/\/diamond-staging-api\.zero-one-group\.com\/api/,
    /^https:\/\/diamond-staging-api\.zero-one\.cloud\/api/,
    'localhost',
  ],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <StrictMode>
    <ChakraProvider theme={theme}>
      <App />
    </ChakraProvider>
  </StrictMode>
);
