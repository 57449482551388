import { Box, Flex, Grid } from '@chakra-ui/react';
import { MultiSelect, Text } from '@diamond/shared/ui';
import { statusData } from '@diamond/shared/utils';
import { useAuthStore } from '@diamond/sol-admin/authentication';
import { useCustomerTypes } from '@diamond/sol-admin-context';
import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import ReactSelect, { Props } from 'react-select';
import shallow from 'zustand/shallow';

const reactSelectStyles: Props['styles'] = {
  container(base) {
    return {
      ...base,
      minWidth: 220,
    };
  },
  menu(base, props) {
    return {
      ...base,
      zIndex: 98, // below overlay
    };
  },
  indicatorSeparator() {
    return {
      display: 'none',
    };
  },
  valueContainer(base, props) {
    return {
      ...base,
      flexWrap: 'nowrap',
    };
  },
  control(base) {
    return { ...base, minHeight: 40, borderRadius: 'var(--chakra-radii-md)' };
  },
};

const distributionChannelValues = [
  'DC',
  'DD',
  'DF',
  'DG',
  'DH',
  'DA',
  'DI',
  'DJ',
  'DK',
  'DL',
  'DM',
  'DN',
  'DO',
  'DP',
  'DQ',
  'DR',
  'DS',
  'DE',
  'DU',
  'DV',
];

const distributionChannelOptions = [
  { label: 'Semua Distribution Channel', value: '' },
  ...distributionChannelValues.map((value) => {
    return { label: value, value: value };
  }),
];

export function ActivitiesSmartSearch() {
  const { setValue, watch } = useFormContext();
  const [
    activityStatus,
    category2,
    selectedCustomerTypes,
    setActivityStatus,
    setSelectedCustomerTypes,
  ] = useAuthStore(
    (s) => [
      s.activityStatus,
      s.category2,
      s.selectedCustomerTypes,
      s.setActivityStatus,
      s.setSelectedCustomerTypes,
    ],
    shallow
  );

  const { options: customerTypeOptions } = useCustomerTypes();

  const statusOptions = useMemo(() => {
    return category2 === '' || category2 === 'semua'
      ? [{ label: 'Semua Status', value: 'semua' }, ...statusData]
      : category2 === 'processed'
      ? [statusData[2]]
      : [statusData[1]];
  }, [category2]);

  const selectedStatus = useMemo(() => {
    return statusOptions.find((it) => it.value === activityStatus);
  }, [activityStatus, statusOptions]);
  const selectedDc = distributionChannelOptions.find(
    (it) => it.value === watch('distribution_channel')
  );
  return (
    <Box my={6} maxW="full">
      <Grid gridTemplateColumns="auto 1fr" gap={8} alignItems="baseline">
        <Text fontSize="sm" fontWeight="bold" whiteSpace="nowrap">
          Smart Search
        </Text>
        <Flex
          justifyContent="flex-start"
          alignItems="flex-start"
          wrap="wrap"
          gap={2}
        >
          <ReactSelect
            id="order_status"
            placeholder="Status"
            styles={reactSelectStyles}
            options={statusOptions}
            value={selectedStatus}
            onChange={(val: any) => {
              const value = val?.value || '';
              setActivityStatus(value);
              setValue('status', value);
            }}
          />
          <ReactSelect
            id="distribution_channel"
            placeholder="Distribution Channel"
            styles={reactSelectStyles}
            value={selectedDc}
            options={distributionChannelOptions}
            onChange={(val: any) => {
              const value = val?.value || '';
              setValue('distribution_channel', value);
            }}
          />
          <MultiSelect
            id="customer_types"
            placeholder="Tipe Customer"
            styles={reactSelectStyles}
            options={customerTypeOptions}
            value={selectedCustomerTypes}
            onChange={(vals) => {
              const selected = vals as typeof selectedCustomerTypes;
              setSelectedCustomerTypes(selected);
              setValue('customer_types', selected);
            }}
          />
        </Flex>
      </Grid>
    </Box>
  );
}

export default ActivitiesSmartSearch;
