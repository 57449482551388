import { CancelReason } from '@diamond/shared/types';
import { useAuthStore } from '@diamond/sol-admin/authentication';
import { useQuery } from '@tanstack/react-query';

import { getCancelReason } from '../queries';

type queryString = {
  size: number;
  page: number;
};

function cancelReasonResponseToMap(data: CancelReason) {
  return {
    ...data,
    cancelReasonMap: data.data.reduce((acc, curr) => {
      if (!acc[curr.reason_id]) acc[curr.reason_id] = curr;
      return acc;
    }, {} as Record<SingleCancelReason['reason_id'], SingleCancelReason>),
  };
}

type SingleCancelReason = CancelReason['data'][number];

export const useCancelReason = (queryString: queryString) => {
  const session = useAuthStore();

  const { data: cancelReasonData } = useQuery({
    queryKey: ['CancelReason'],
    queryFn: () => getCancelReason(session.access_token, queryString),
    select: cancelReasonResponseToMap,
    staleTime: 10 * 60 * 1000, // 10 Minutes
    gcTime: 30 * 60 * 1000, // 30 Minutes
  });

  return {
    cancelReasonData,
  };
};

export type UseCancelReasonData = ReturnType<
  typeof useCancelReason
>['cancelReasonData'];
