import {
  Box,
  Flex,
  HStack,
  Icon,
  Image,
  Link as ChakraLink,
  List,
  ListItem,
  Stack,
  Tooltip,
  VStack,
} from '@chakra-ui/react';
import { BackofficeOrderData } from '@diamond/shared/types';
import { Text } from '@diamond/shared/ui';
import {
  paymentMethodFormatterTruncate,
  statusFormatter,
} from '@diamond/shared/utils';
import {
  adminCurrencyFormatter,
  adminDateFormatter,
} from '@diamond/shared/utils';
import { InfoOutlined, WarningOutlined } from '@mui/icons-material';
import { createColumnHelper } from '@tanstack/react-table';
import { Link } from 'react-router-dom';

import DokumenPOIcon from '../../assets/images/file-text.svg';
import DokumenPOIconDisabled from '../../assets/images/file-text-disabled.svg';
import BuktiBayarIcon from '../../assets/images/receipt.svg';
import BuktiBayarIconDisabled from '../../assets/images/receipt-disabled.svg';

const columnHelper = createColumnHelper<BackofficeOrderData>();

function openMultipleLinks(links: string[]) {
  links.forEach((url) => window.open(url));
}

type ColumnsOptions = {
  customerTypeRender?: (id: string) => string;
  cancelReasonRender?: (
    status: BackofficeOrderData['status'],
    id: BackofficeOrderData['cancel_reason_id'],
    sap_note: string
  ) => string;
};

export const columns = (options?: ColumnsOptions) => [
  columnHelper.accessor('division', {
    cell: (info) => {
      const masking = [
        {
          label: 'Diamond',
          value: 'diamond',
        },
        {
          label: 'Trading',
          value: 'trading',
        },
        {
          label: 'FineFood',
          value: 'food',
        },
        {
          label: 'FineFood',
          value: 'fnb',
        },
        {
          label: 'Equipment',
          value: 'equipment',
        },
      ];
      const outsideDAMasking = [
        {
          label: 'Food',
          value: 'diamond',
        },
        {
          label: 'Food',
          value: 'trading',
        },
        {
          label: 'Food',
          value: 'food',
        },
        {
          label: 'Food',
          value: 'fnb',
        },
        {
          label: 'Equipment',
          value: 'equipment',
        },
      ];
      const catalogMasking = (catalog: string) => {
        if (info.row.original.ship_to['distribution_channel'] === 'DA') {
          return masking.filter((val) => val.value === catalog)[0];
        } else {
          return outsideDAMasking.filter((val) => val.value === catalog)[0];
        }
      };

      return catalogMasking(info.getValue()).label;
    },
    header: 'Katalog',
  }),
  columnHelper.accessor('created_at', {
    cell: (info) => {
      return adminDateFormatter(info.getValue());
    },
    header: 'Tanggal PO',
  }),
  columnHelper.accessor('cg_1_business_segmentation', {
    cell: (info) => {
      if (options?.customerTypeRender) {
        const ct = info.row.original.ship_to[
          'cg_1_business_segmentation'
        ] as string;
        return options.customerTypeRender(ct);
      }
      return '-';
    },
    header: 'Tipe Customer',
    enableSorting: false,
  }),
  columnHelper.accessor('sub_po_number', {
    cell: (info) => (
      <Box p="0.375rem">
        <ChakraLink
          as={Link}
          textDecoration="underline"
          to={`/activities/${info.row.original.id}`}
        >
          {info.getValue()}
        </ChakraLink>
      </Box>
    ),
    header: 'Nomor Sub PO',
  }),
  columnHelper.accessor('transaction_id', {
    cell: (info) => (
      <ChakraLink
        as={Link}
        textDecoration="underline"
        to={`/activities/meta/${info.row.original.order_meta_id}`}
      >
        {info.getValue()}
      </ChakraLink>
    ),
    header: 'Nomor PO',
  }),
  columnHelper.accessor('delivery_note', {
    cell: (info) => (
      <Stack p="0.375rem" w={40} mt={-3} mb={-3}>
        {info.row.original.product_notes.reduce(
          (acc, value) => acc || value !== '',
          false
        ) ? (
          <Flex>
            <Icon as={WarningOutlined} fontSize="sm" color="#FF9500" mt={0.5} />
            <ChakraLink
              as={Link}
              textDecoration="underline"
              to={`/activities/${info.row.original.id}`}
            >
              <Text
                ml={1}
                fontSize="sm"
                whiteSpace="normal"
                wordBreak="break-word"
                noOfLines={2}
                textOverflow="ellipsis"
              >
                Product{' '}
                {info.getValue() !== '' && info.getValue() !== null ? '&' : ''}
              </Text>
            </ChakraLink>
          </Flex>
        ) : (
          '-'
        )}
        {info.getValue() !== '' && info.getValue() !== null ? (
          <Flex>
            {!info.row.original.product_notes.reduce(
              (acc, value) => acc || value !== '',

              false
            ) ? (
              <Icon
                as={WarningOutlined}
                fontSize="sm"
                color="#FF9500"
                mt={0.5}
              />
            ) : undefined}

            <ChakraLink
              as={Link}
              textDecoration="underline"
              to={`/activities/${info.row.original.id}`}
            >
              <Text
                ml={4}
                fontSize="sm"
                whiteSpace="normal"
                wordBreak="break-word"
                noOfLines={2}
                textOverflow="ellipsis"
              >
                Pengiriman
              </Text>
            </ChakraLink>
          </Flex>
        ) : (
          '-'
        )}
      </Stack>
    ),
    header: 'Catatan',
  }),
  columnHelper.accessor('so_number', {
    cell: (info) => (info.getValue() ? info.getValue() : '-'),
    header: 'Nomor SO',
  }),
  columnHelper.accessor('do_number', {
    cell: (info) => {
      return (
        <Box h={1}>
          <List
            listStyleType="disc"
            mt={info.getValue().length === 1 ? -2 : -7}
          >
            {Array.isArray(info.getValue()) &&
            info.getValue().length === 1 &&
            info.getValue()[0] === ''
              ? '-'
              : info
                  .getValue()
                  .map((item, index) => (
                    <ListItem key={index}> {item}</ListItem>
                  ))}
          </List>
        </Box>
      );
    },
    header: 'Nomor DO',
  }),
  columnHelper.accessor('status', {
    cell: (info) => {
      if (options?.cancelReasonRender) {
        return options?.cancelReasonRender(
          info.getValue(),
          info.row.original.cancel_reason_id,
          info.row.original.sap_note || ''
        );
      }
      return statusFormatter(info.getValue());
    },
    header: 'Status Order',
  }),
  columnHelper.accessor('payment_method', {
    cell: (info) =>
      `${paymentMethodFormatterTruncate(String(info.getValue()))} ${
        info.getValue() === 'term_of_payment'
          ? `${info.row.original.user.term_of_payment_days} Hari`
          : ''
      }`,
    header: 'Pembayaran',
  }),
  columnHelper.accessor('attachments', {
    cell: (info) => {
      const isDocumentPO = info.getValue().po_docs.length > 0;
      const isPaymentProof = info.getValue().payment_proofs.length > 0;
      return (
        <HStack>
          <Box alignContent="center" alignSelf="center">
            {isDocumentPO ? (
              <Tooltip label="Dokumen PO">
                <ChakraLink
                  cursor={isDocumentPO ? 'pointer' : 'not-allowed'}
                  onClick={() => openMultipleLinks(info.getValue().po_docs)}
                  data-nomor-po={info.row.original.transaction_id}
                >
                  <Image src={DokumenPOIcon} alt="Dokumen PO" color="gray" />
                </ChakraLink>
              </Tooltip>
            ) : (
              <Tooltip label="Dokumen PO">
                <Image
                  src={DokumenPOIconDisabled}
                  alt="Dokumen PO"
                  color="gray"
                />
              </Tooltip>
            )}
          </Box>
          <Box alignContent="center" alignSelf="center">
            {isPaymentProof ? (
              <Tooltip label="Bukti Bayar">
                <ChakraLink
                  id={`bukti-bayar-${info.row.original.transaction_id}`}
                  as={Link}
                  cursor={isPaymentProof ? 'pointer' : 'not-allowed'}
                  to={`/activities/${info.row.original.id}/payment-proof`}
                >
                  <Image src={BuktiBayarIcon} alt="Bukti Bayar" color="gray" />
                </ChakraLink>
              </Tooltip>
            ) : (
              <Tooltip label="Bukti Bayar">
                <Image
                  src={BuktiBayarIconDisabled}
                  alt="Bukti Bayar"
                  color="gray"
                />
              </Tooltip>
            )}
          </Box>
        </HStack>
      );
    },
    header: () => {
      return (
        <HStack mt="4">
          <Box>Lampiran</Box>
          <Box>
            <Tooltip
              borderRadius="lg"
              p="4"
              hasArrow
              label={
                <VStack w="fit-content">
                  <Box>Mohon aktifkan pop-up windows pada browser Anda:</Box>
                  <Box>
                    <Image
                      src="/assets/images/allow-popup-chrome.png"
                      alt="SUKANDA ONELINK"
                    />
                  </Box>
                </VStack>
              }
            >
              <InfoOutlined />
            </Tooltip>
          </Box>
        </HStack>
      );
    },
  }),
  columnHelper.accessor('ship_to.name', {
    cell: (info) => info.getValue(),
    header: 'Nama Shipto',
  }),
  columnHelper.accessor('ship_to.sap_no_shipto', {
    cell: (info) => info.getValue(),
    header: 'Nomor Shipto',
  }),
  columnHelper.accessor('ship_to.distribution_channel', {
    cell: (info) => info.getValue(),
    header: 'Distribution Channel',
  }),
  columnHelper.accessor('ship_to.city', {
    cell: (info) => info.getValue(),
    header: 'Kota',
  }),
  columnHelper.accessor('processed_at', {
    cell: (info) => {
      return adminDateFormatter(info.getValue());
    },
    header: 'Tanggal Proses Order',
  }),
  columnHelper.accessor('subtotal', {
    cell: (info) => adminCurrencyFormatter(info.getValue()),
    header: 'Total Order Per Divisi',
  }),
  columnHelper.accessor('total', {
    cell: (info) => adminCurrencyFormatter(info.getValue()),
    header: 'Total PO (Cart)',
  }),
  columnHelper.accessor('client_type', {
    cell: (info) => info.getValue(),
    header: 'Platform',
  }),
  columnHelper.accessor('delivery_link', {
    cell: (info) => {
      if (info.getValue()) {
        return (
          <ChakraLink href={`${info.getValue()}`} isExternal>
            Link
          </ChakraLink>
        );
      } else {
        return (
          <Text cursor="not-allowed" color="gray">
            Link
          </Text>
        );
      }
    },
    header: 'Delivery Info',
  }),
  columnHelper.accessor('invoice_link', {
    cell: (info) => {
      if (info.getValue()) {
        return (
          <ChakraLink href={`${info.getValue()}`} isExternal>
            Link
          </ChakraLink>
        );
      } else {
        return (
          <Text cursor="not-allowed" color="gray">
            Link
          </Text>
        );
      }
    },
    header: 'Invoice',
  }),
];
